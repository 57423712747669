export class ProductCartRequestModel {
    Action: number = 10;//10 is for AddToCart. Ref: T_SfCartAction
    PID: number | undefined;
    MultiPids: string | undefined;
    OS: string | undefined;
    Quantity: number  = 0;
    Ref: string | undefined;
    Frequency: number | undefined;
}

export enum T_SfCartAction {
    AddProduct = 10,
    UpdateProduct = 20,
    RemoveProduct = 30,
    ApplyPromoCode = 40,
    RemovePromoCode = 50,
    ApplyCredits = 60,
    RemoveCredits = 70,
    SaveForLater_Add = 80,
    SaveForLater_Remove = 90,
    Wishlist_Add = 100,
    Wishlist_Remove = 110
}
    /*
    ProductCartRequestModel Action Enums from T_SfCartAction:

    AddProduct = 10,
    UpdateProduct = 20,
    RemoveProduct = 30,
    ApplyPromoCode = 40,
    RemovePromoCode = 50,
    ApplyCredits = 60,
    RemoveCredits = 70,
    SaveForLater_Add = 80,
    SaveForLater_Remove = 90,
    Wishlist_Add = 100,
    Wishlist_Remove = 110 
    */