export class CartOrderWrapper {
    TotalItemsInCart: number = 0;
    cartModel: CartModel | undefined;
}
export class CartModel {
    BogoImage: string | undefined;
    BogoReminderBox: BogoReminderBoxModel | undefined;
    FreeShippingThresholdMessage: string | undefined;
    Message: string | undefined;
    StoreFrontId: number | undefined;
    TotalItemsInCart: number | undefined;
    SfCartOrder: OrderModel | undefined;
}

export class OrderModel {
    OrderID: number;
    EncryptedOrderID: string | undefined;
    CustomerEmail: string | undefined;
    OrderTotal: string | undefined;
    OrderStatus: string | undefined;
    OrderDate: string | undefined;
    ErrorMessage: string | undefined;
    Currency: string | undefined;
    PurchaseValue: number | undefined;
    OrderItemsDynamicModel: OrderItem[] | undefined;
    DateCreatedEpochTime: number;
    DateUpdatedEpochTime: number;
    

    STORE_FRONT_ID: number;
    CART_ORDER_SUBTOTAL: number;
    CART_ORDER_TOTAL: number;
    SfCartShippingUi: SfCartShippingUiRaw;
    ORDER_SUBTOTAL: number;
    ORDER_TOTAL: number;
    ORDER_TOTAL_USD: number;
    SubtotalUiCultureFormatted: string;
    TotalUiCultureFormatted: string;
    TotalUiCultureFormatted_USD: string;
    TAX: number;
    TAX_UiCultureFormatted: string;
    SfCustomer: SfCartCustomer | undefined;
    Credits: SfCartCreditsUiClass | undefined;
    PromoCode: PromoCode | undefined;
}
export class SfCartCustomer {
    DateCreatedEpochTime: number;
    SF_CUSTOMER_ID: number;
}

export class OrderItem {
    Sku: string | undefined;
    Name: string | undefined;
    ImageUrl: string | undefined;
    ConsumerPrice: string | undefined;
    Quantity: number | undefined;
    TotalPrice: number | undefined;
    UrlName: string | undefined;
    ProductID: number | undefined;
    OrderItemID: number | undefined;
    Teaser: string | undefined;
    IsBogo: boolean;
    URL_NAME: string;
}

export class BogoReminderBoxModel {
    BogoReminderBoxMessage: string | undefined;
    ShowBogoReminderBox: boolean;
}


export class SfCartCreditsUiClass {

    CART_ORDER_TOTAL_AFTER_CREDITS: number;
    TotalUiCultureFormatted_AfterCredits: number;
    CreditsApplied: number;
    Ui_CreditsApplied: string;
    CustomerAvailableCredits: number;
    Ui_CustomerAvailableCredits: string;

}

export class SfCartShippingUiRaw {
    ShipOptionID: number;
    ShippingName: number;
    ShippingFreeThresHold: number;
    ShippingFreeThresHold_UIFormatted: string;
    //OrderShippingCost: number;
    SfCartOrderShipCost: number;
    //OrderShippingCostUiCultureFormatted: string;
    SfCartOrderShipCostUi: string;
    RemainingToFreeShipThresHold: number;
    RemainingToFreeShipThresHoldUi: string;
    PercentageRemainingToFreeShipping: number;
    FreeShippingThresholdMessage: string;
    HideShippingEntirely: boolean;
}
export class PromoCode{
    Discount: number;
    Discount_UIFormatted: string;
    PromoCode: string;
    ShowFreeShipping: boolean;
}