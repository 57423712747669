import * as React from "react";
import * as ReactDOM from "react-dom";
import { CartMainComponentReact } from "./components/CartMainComponentReact"

function render() {
    ReactDOM.render(<CartMainComponentReact
        ref={(CartMainComponentReact) => { (window as any).CartMainComponentReact = CartMainComponentReact }} />,
        document.getElementById("divCartDropDown"));
}

if (document.readyState !== 'loading') {
    render();
} else {
    document.addEventListener('DOMContentLoaded', render);
}
